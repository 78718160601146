<script setup>
/* eslint-disable import/no-unresolved */
import { ref, computed, useTrackEvent } from '#imports';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

defineProps({
  title: {
    type: String,
    default: 'Новости',
  },
  news: {
    type: Array,
    required: true,
  },
  whiteBackground: {
    type: Boolean,
    default: false,
  },
});

const swiper = ref();
const modules = [Navigation];

const breakpoints = {
  768: { direction: 'horizontal', spaceBetween: 24 },
};

const isBeginning = computed(
  () => swiper.value && swiper.value.realIndex !== 0
);
const isEnd = computed(() => swiper.value && !swiper.value.isEnd);

function setSwiper(swiperInstance) {
  swiper.value = swiperInstance;
}

function trackEvent(eventType, name) {
  useTrackEvent(eventType, {
    props: {
      name,
    },
  });
}
</script>

<template>
  <div class="main-news-block">
    <div class="main-news-block__header">
      <div class="main-news-block__title">{{ title }}</div>
      <NuxtLink href="/news" class="main-news-block__link"
        >показать еще</NuxtLink
      >
    </div>
    <div
      class="carousel"
      :class="{ 'carousel--white-background': whiteBackground }"
    >
      <template v-if="isBeginning">
        <div class="carousel__shadow carousel__shadow--before"></div>
        <button
          class="carousel__button carousel__button--prev"
          @click="
            () => {
              swiper.slidePrev();
              trackEvent('click', 'News carousel, previous slide');
            }
          "
        ></button>
      </template>
      <Swiper
        :modules="modules"
        slides-per-view="auto"
        :space-between="12"
        :slides-per-group="3"
        direction="vertical"
        css-mode
        :breakpoints="breakpoints"
        @swiper="setSwiper"
      >
        <SwiperSlide v-for="item in news" :key="item._id">
          <NuxtLink
            :to="{ name: 'news-slug', params: { slug: item.slug.current } }"
          >
            <MainNewsBlockItem
              :news-item="item"
              :white-background="whiteBackground"
            />
          </NuxtLink>
        </SwiperSlide>
      </Swiper>
      <template v-if="isEnd">
        <button
          class="carousel__button carousel__button--next"
          @click="
            () => {
              swiper.slideNext();
              trackEvent('click', 'News carousel, next slide');
            }
          "
        ></button>
        <div class="carousel__shadow carousel__shadow--after"></div>
      </template>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.main-news-block {
  &__title {
    font-size: 24px;
    line-height: 30px;
    font-family: var(--title-font-family);
    font-weight: 700;
  }

  &__link {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    display: flex;
    align-items: center;
    padding: 4px 6px;
    transition: var(--default-transition) opacity;
    border-radius: 8px;
    background-color: #74ffab;
    gap: 6px;

    &:hover {
      opacity: 0.8;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 16px;
    margin-bottom: 8px;

    @media (--small-vp) {
      align-items: center;
      margin-bottom: 20px;
    }
  }
}

.carousel {
  position: relative;
  height: 200px;
  padding: 20px 20px 16px;
  border-radius: 16px;
  background-color: rgba(var(--white-color--rgb), 0.7);

  &__shadow {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    width: 190px;

    &--before {
      right: auto;
      left: 0;
      border-radius: 16px 0 0 16px;
      background: linear-gradient(270deg, transparent 26.56%, #ffeaf5 100%);
    }

    &--after {
      left: auto;
      right: 0;
      border-radius: 0 16px 16px 0;
      background: linear-gradient(270deg, #ffeaf5 26.56%, transparent 100%);
    }
  }

  &__button {
    position: absolute;
    z-index: 3;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--accent-color);
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 10px;
      height: 17px;
      background-image: url('~/assets/images/chevron_right.svg');
    }

    &--next {
      left: auto;
      right: 16px;
    }

    &--next::after {
      transform: translateX(1px);
    }

    &--prev {
      left: 16px;
      right: auto;
    }

    &--prev::after {
      transform: translateX(-1px) rotate(180deg);
    }
  }

  &--white-background {
    background-color: var(--white-color);
  }

  &--white-background .carousel__shadow {
    &--before {
      background: linear-gradient(270deg, transparent 26.56%, #fffcfe 100%);
    }

    &--after {
      background: linear-gradient(270deg, #fffcfe 26.56%, transparent 100%);
    }
  }

  &::v-deep(.swiper) {
    display: none;
  }

  &::v-deep(.swiper.swiper-initialized) {
    display: block;
  }

  &::v-deep(.swiper-slide) {
    display: block;
    width: auto;
  }

  @media (--small-vp) {
    display: block;
    height: 360px;
    padding: 12px 8px;
    border-radius: 12px;

    &__shadow {
      left: 0;
      right: 0;
      width: 100%;
      height: 50px;

      &--before {
        top: 0;
        bottom: auto;
        border-radius: 12px 12px 0 0;
        background: linear-gradient(
          0deg,
          rgba(255, 237, 242, 0%) 0%,
          #ffedf3 60.94%
        );
      }

      &--after {
        top: auto;
        bottom: 0;
        border-radius: 0 0 12px 12px;
        background: linear-gradient(
          180deg,
          rgba(255, 237, 242, 0%) 0%,
          #ffedf3 60.94%
        );
      }
    }

    &__button {
      &--prev {
        top: -20px;
        bottom: auto;
        transform: rotate(90deg);
        left: 0;
        right: 0;
        margin: auto;
      }

      &--next {
        bottom: -20px;
        top: auto;
        transform: rotate(90deg);
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    &::v-deep(.swiper) {
      height: 336px;
    }

    &::v-deep(.swiper-slide) {
      height: fit-content;
    }
  }
}
</style>
